export default defineNuxtRouteMiddleware((to, from) => {
  const isExecuteLoadEvent = useState<boolean>(
    'isExecuteLoadEvent',
    () => true,
  );
  // url直接アクセスの場合はフラグを閉じる（loadイベントが自動的に発生する為）
  if (!from.name) {
    isExecuteLoadEvent.value = false;
    return;
  }

  // それ以外はtrue
  isExecuteLoadEvent.value = true;
});
